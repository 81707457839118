import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import styled from '@emotion/styled';

import { getTheme } from '../utility/theme';
import Layout from '../components/Layout';
import { COLOR } from '../constants';

const muiTheme = (userTheme: string) =>
  createMuiTheme({
    palette: {
      type: userTheme === 'dark' ? 'dark' : 'light',
      primary: {
        main: COLOR.PRIMARY,
      },
      text: {
        primary: userTheme === 'dark' ? '#ffffff' : '#333333',
      },
    },
  });

const Row = styled.div`
  margin-bottom: 1rem;

  button {
    margin-right: 1rem;
  }
`;

function encode(data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

const API_URL = 'https://journalgems-api.netlify.app/add?';
export default function AddStuff() {
  const [url, setUrl] = useState('');
  const [notes, setNotes] = useState('');

  const [isSending, setSending] = React.useState(false);


  const userTheme = getTheme();
  const theme = muiTheme(userTheme);

  const handleUrl = e => {
    setUrl(e.target.value);
  };
  const handleNotes = e => {
    setNotes(e.target.value);
  }
  const handleClear = () => {
    setNotes('');
    setUrl('');
  }


  const handleSubmit = e => {
    e.preventDefault();

    setSending(true);

    const urlString = API_URL + 'url=' + url + '&notes=' + notes;

    fetch(urlString, {
      method: 'GET',
      mode: 'no-cors'
    })
      .then(a => {
        setSending(false);
        alert('Stuff Added');
      })
      .catch(error => alert(error));
  };

  const disabled = isSending || (url === '' && notes === '');

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <h1>Add Stuff</h1>
        <form
          name="add-stuff"
          method="post"
          onSubmit={handleSubmit}
        >
          <Row>
            <TextField label="url" name="stuff" type="text" onChange={handleUrl} fullWidth value={url}></TextField>
          </Row>
          <Row>
            <TextField label="notes" name="notes" type="text" onChange={handleNotes} fullWidth multiline value={notes}></TextField>
          </Row>
          <Row>
            <Button type="submit" color="primary" variant="contained" disabled={disabled}>
              Submit
            </Button>
            <Button type="button" color="primary" variant="outlined" disabled={isSending} onClick={handleClear}>
              Clear
            </Button>
          </Row>
        </form>

        <br />
        <br />
      </Layout>
    </ThemeProvider>
  );
}
